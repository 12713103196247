import React from "react";
import { NavbarTwo } from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import HFCV1 from "../asset/assembly/HFCV 1.png";
import HFCV2 from "../asset/assembly/HFCV 2.png";
import HFCV3 from "../asset/assembly/HFCV 3.png";
import Shutoff1 from "../asset/assembly/Shutoff 1.png";
import Shutoff2 from "../asset/assembly/Shutoff 2.png";
import Shutoff3 from "../asset/assembly/Shutoff 3.png";
import Shutoff4 from "../asset/assembly/Shutoff 4.png";
import component_transfer from "../asset/assembly/component transfer.png";
import component_transfer2 from "../asset/assembly/component transfer2.png";
import guide_sleeve1 from "../asset/assembly/GUIDE SLEEVE 1.png";
import guide_sleeve2 from "../asset/assembly/GUIDE SLEEVE 2.png";
import guide_sleeve3 from "../asset/assembly/GUIDE SLEEVE 3.png";
import snout_plug from "../asset/assembly/Snout Plug.png";
import snout_plug2 from "../asset/assembly/Snout Plug2.png";
import probe_housing from "../asset/assembly/probe housing.png";
import probe_housing2 from "../asset/assembly/probe housing2.png";
import ball_valve from "../asset/assembly/BALL VALVE.png";
import cg_motor from "../asset/assembly/CG MOTOR.png";
import special_torque from "../asset/assembly/Special Torque Wrench.png";
import centre_disc from "../asset/assembly/CENTRE DISC PRESSURE ASSEMBLY.png";

const AssemblyComponent = () => {
  return (
    <div>
      <NavbarTwo />
      <div className="2xl:pt-[120px] pt-[80px] xl:pt-[110px] lg:pt-[100px]">
        <div className="max-w-7xl mx-auto p-4 md:p-8">
          {/* Title Section */}
          <div className="flex items-center justify-center flex-col p-4 mb-8">
            <h3 className="lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">
              HIGH FLOW CONTROL VALVE (HFCV)
            </h3>
          </div>
          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            <img className="rounded-lg object-cover" src={HFCV1} />
            <img className="rounded-lg object-cover" src={HFCV2} />
            <img className="rounded-lg object-cover" src={HFCV3} />
          </div>
          {/* Description Box */}
          <div className=" p-6 ">
            <p className="text-gray-600 text-[.8rem] text-center xl:text-[1rem]">
              We offer a wide range of precision-engineered components,
              including Poppets, Plugs, Pins, Valves Piston etc. Manufactured
              from high-performance materials such as Stainless Steel, Titanium,
              Incoloy, Inconel, 17-4 pH with required heat treated condition,
              our components are designed with tight tolerances of up to 2
              microns and Ra finishes up to 0.02Ra. These parts are ideal for
              use in power and energy sectors providing durable and reliable
              solutions for various applications.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">
          {/* Title Section */}
          <div className="flex items-center justify-center flex-col p-4 mb-8">
            <h3 className="lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">
              SHUT-OFF ROD DRIVE MECHANISM
            </h3>
          </div>
          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
            <img className="rounded-lg object-cover" src={Shutoff1} />
            <img className="rounded-lg object-cover" src={Shutoff2} />
            <img className="rounded-lg object-cover" src={Shutoff3} />
            <img className="rounded-lg object-cover" src={Shutoff4} />
          </div>
          {/* Description Box */}
          <div className=" p-6 ">
            <p className="text-gray-600 text-[.8rem] text-center xl:text-[1rem]">
              Mechanical shut-off rods are a crucial safety system for reactors,
              designed to provide reliable negative reactivity and shutdown the
              reactor quickly. In a reactor trip, the moderator is dumped to
              enhance the shutdown margin. The drive mechanism ensures rod
              operation (raising, lowering, holding), fail-safe and non-reverse
              scram features, compliance with size constraints, position and end
              limit indications, and meets environmental and maintenance
              requirements. These Drive Mechanisms were developed, tested and
              supplied to BARC by us
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">
          {/* Title Section */}
          <div className="flex items-center justify-center flex-col p-4 mb-8">
            <h3 className="lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">
              COMPONENT TRANSFER ASSEMBLY
            </h3>
          </div>
          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <img className="rounded-lg object-cover" src={component_transfer} />
            <img
              className="rounded-lg object-cover"
              src={component_transfer2}
            />
          </div>
          {/* Description Box */}
          <div className=" p-6 ">
            <p className="text-gray-600 text-[.8rem] text-center xl:text-[1rem]">
              Mechanical shut-off rods are a crucial safety system for reactors,
              designed to provide reliable negative reactivity and shutdown the
              reactor quickly. In a reactor trip, the moderator is dumped to
              enhance the shutdown margin. The drive mechanism ensures rod
              operation (raising, lowering, holding), fail-safe and non-reverse
              scram features, compliance with size constraints, position and end
              limit indications, and meets environmental and maintenance
              requirements. These Drive Mechanisms were developed, tested and
              supplied to BARC by us
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">
          {/* Title Section */}
          <div className="flex items-center justify-center flex-col p-4 mb-8">
            <h3 className="lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">
              GUIDE SLEEVE TOOL ASSEMBLY
            </h3>
          </div>
          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            <img className="rounded-lg object-cover" src={guide_sleeve1} />
            <img className="rounded-lg object-cover" src={guide_sleeve2} />
            <img className="rounded-lg object-cover" src={guide_sleeve3} />
          </div>
          {/* Description Box */}
          <div className=" p-6 ">
            <p className="text-gray-600 text-[.8rem] text-center xl:text-[1rem]">
              The guide sleeve tool is an assembly that ensures a smooth passage
              for fuel bundles and shield plugs between the fuel channel and the
              fueling machine. It is moved by the fueling machine ram using an
              insertion tool and is locked in place during operation.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">
          {/* Title Section */}
          {/* <div className="flex items-center justify-center flex-col p-4 mb-8">
            <h3 className="lg:text-[2.2rem] text-[1.2rem] bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">
              GUIDE SLEEVE TOOL ASSEMBLY
            </h3>
          </div> */}

          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <div>
                <h3 className="text-center font-semibold text-xl pb-1 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold] ">SNOUT PLUG ASSEMBLY</h3>
                <img className="rounded-lg object-cover" src={snout_plug} />
            </div>
            <div>
                <h3 className="text-center font-semibold text-xl pb-1 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">HIGH PRECISION GATE VALVE ASSEMBLY</h3>
                <img className="rounded-lg object-cover" src={snout_plug2} />
            </div>
          </div>
          {/* Description Box */}
          {/* <div className=" p-6 ">
            <p className="text-gray-600 text-[.8rem] text-center xl:text-[1rem]">
              The guide sleeve tool is an assembly that ensures a smooth passage
              for fuel bundles and shield plugs between the fuel channel and the
              fueling machine. It is moved by the fueling machine ram using an
              insertion tool and is locked in place during operation.
            </p>
          </div> */}
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">

          {/* Image Grid */}
          <div className="flex flex-col md:flex-row gap-8 mb-8 align-center justify-center">
            <div className="text-center">
                <h3 className="center font-semibold text-xl pb-2 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold] ">LINEAR BALLWAY BEARING ASSEMBLY
                </h3>
                <img className="rounded-lg object-cover" src={probe_housing} />
            </div>
            <div className="text-center">
                <h3 className="font-semibold text-xl pb-2 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">PROBE HOUSING ASSEMBLY
                </h3>
                <img className="rounded-lg object-cover" src={probe_housing2} />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">

          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-8">
            <div>
                <h3 className="text-center font-semibold text-xl p-2 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">PRECISION BALL VALVE ASSEMBLY
                </h3>
                <img className="rounded-lg object-cover" src={ball_valve} />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">

          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-8">
            <div>
                <h3 className="text-center font-semibold text-xl p-2 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">CG MOTOR ASSEMBLY
                </h3>
                <img className="rounded-lg object-cover" src={cg_motor} />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">

          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-8">
            <div>
                <h3 className="text-center font-semibold text-xl p-2 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">SPECIAL TORQUE WRENCH ASSEMBLY
                </h3>
                <img className="rounded-lg object-cover" src={special_torque} />
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto p-4 md:p-8">

          {/* Image Grid */}
          <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-8">
            <div>
                <h3 className="text-center font-semibold text-xl p-2 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]">CENTRE DISC PRESSURE ASSEMBLY 
                </h3>
                <img className="rounded-lg object-cover" src={centre_disc} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AssemblyComponent;
