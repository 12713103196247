import React from 'react'

const HomeVideo = () => {
    return (
        <div className="py-8 px-7 lg:px-24 lg:pt-4 lg:pb-16 aboutHomeContainer items-center justify-center">
           <div className="text-center mb-8">
           <h2 className='lg:text-[2.2rem] text-[1.2rem] pb-4 bg-gradient-to-r from-[#99821d] via-[#ccc595] to-[#9d8944] inline-block text-transparent bg-clip-text uppercase lg:leading-[2.5rem] font-[GilroyBold]'>Behind the Scenes</h2>
           
           <iframe 
               width="100%" 
               className="h-[35vh] md:h-[80vh]" 
               src="https://www.youtube.com/embed/AeWdok7ERhM" 
               title="HARDCOATS final" 
               frameborder="0" 
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
               referrerpolicy="strict-origin-when-cross-origin" 
               allowfullscreen
           />
           </div>v 
       </div>
      );
};

export default HomeVideo